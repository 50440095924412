import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { useAuth } from '../contexts/AuthContext';
import Container from 'react-bootstrap/Container';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ScrollTop } from 'primereact/scrolltop';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressBar } from 'primereact/progressbar';
import '../config.js';
import { Col } from 'react-bootstrap';

const Clients = () => {
  const { typeId, typeName, type } = useParams();
  const [clients, setClients] = useState([]);
  const [lmsConversion, setLmsConversion] = useState([]);
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState();
  const { logout } = useAuth();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.EQUALS},
    lms: { value: null, matchMode: FilterMatchMode.STARTS_WITH},
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [lms, setLms] = useState([]);
  const msgs = useRef(null);
  const [createVisible, setCreateVisible] = useState(false);
  const [newClientName, setNewClientName] = useState('');
  const [newClientLms, setNewClientLms] = useState(-1);
  const [newLmsName, setNewLmsName] = useState('');
  const toast = useRef(null);
  const toastBC = useRef(null);
  const op = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    const fetchClients = async () => {
      getClients();
    };

    fetchClients();
  }, [typeId, typeName, type]);

  const getClients = async () => {
    try {
      setLoading(true);
      setClients([]);
      const token = localStorage.getItem('token');
      const response = await axios.get(global.config.url + `/clients/By${type}/${typeId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      try{
          const lmsResponse = await axios.get(global.config.url + '/clients/lmsConversion', {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });
          setLmsConversion(lmsResponse.data);
          setLms(Object.values(lmsResponse.data));
          if(response.data.length === 0){
            setLoading(false);
            setClients([]);
            return;
          }

          let responseLocations = [];
          if(type === 'Customer'){
            responseLocations = await axios.get(global.config.url + '/locations/ByCustomer/' + typeId, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
              for(let i = 0; i < response.data.length; i++){
                //get location in responseLocations where responseLocation.data.id === response.data[i].locationId
                let location = responseLocations.data.find(location => location.id === response.data[i].locationId);
                response.data[i].location = location.name;
              }
          }
          else{
            responseLocations = await axios.get(global.config.url + '/locations/' + response.data[0].locationId, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            for(let i = 0; i < response.data.length; i++){
              response.data[i].location = responseLocations.data.name;
            }
          }


          setLoading(false);
          //add item to clients with lmsConversion value according to client.lms
          for(let i = 0; i < response.data.length; i++){
            response.data[i].lmsConversion = lmsResponse.data[response.data[i].lms];
          }
          setClients(response.data);
          console.log(response.data)
      } catch(error){
          console.error('Error fetching lmsConversion:', error);
          if (error.response && error.response.status === 401) {
            // Logout and redirect to login screen
            await logout();
            navigate('/login');
          }
      }

    } catch (error) {
      console.error('Error fetching client:', error);
      if (error.response && error.response.status === 401) {
        // Logout and redirect to login screen
        await logout();
        navigate('/login');
      }
    }
  };

  const getAccessPoints = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a client to view its access points.'});
    }
    else{
      navigate(`/accesspoints/client/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const getLogs = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a client to view its logs.'});
    }
    else{
      navigate(`/logs/client/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const getClientLogs = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a client to view its logs.'});
    }
    else{
      navigate(`/clientlogs/client/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };


  const openComPortForm = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a client to view its logs.'});
    }
    else{
      let url = "https://airtable.com/appsFESx3sxgOSOKG/shr4FIQpDLfiN05Pj?prefill_PC+Name=" + rowSelectionModel.name + "&prefill_Site+name=" + rowSelectionModel.location;
      // navigate(`/clientlogs/client/${rowSelectionModel.name}/${rowSelectionModel.id}`);
      window.open(url, "_blank");
    }
  }

  const startContent = (
    <React.Fragment>
        <Button onClick={() => getAccessPoints()} label="Get Access Points" icon="pi pi-qrcode" className="p-button-success mx-3" tooltip="Get access points for this client" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => getLogs()} label="Get Access Logs" icon="pi pi-file" className="p-button" tooltip="Get access logs for this client" tooltipOptions={{position:"bottom"}}/>
        {/* <Button onClick={() => getClientLogs()} label="Get Client Logs" icon="pi pi-file" className="p-button-danger mx-3" tooltip="Get client logs for this client" tooltipOptions={{position:"bottom"}}/> */}
        <Button onClick={() => openComPortForm() } label="Open Com Port Config" icon="pi pi-clog" className="p-button-secondary mx-3" tooltip="Open Com Port for this client" tooltipOptions={{position:"bottom"}}/>
    </React.Fragment>
);

const endContent = (
  <React.Fragment>
      <span className="p-input-icon-right mx-2">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Filter by name or location name" tooltip="Start typing a client or location name to filter" tooltipOptions={{position: "bottom"}} />
      </span>
      <Button icon="pi pi-refresh" className="p-button-text" onClick={() => getClients()} disabled={loading} tooltip="Refresh clients" tooltipOptions={{position: "bottom"}} />
      <Button icon="pi pi-plus" className="p-button-success mr-3 ml-2" rounded disabled={loading} onClick={() => setCreateVisible(true)} tooltip="Create new client" tooltipOptions={{position: "bottom"}} />
      {/* <Button icon="pi pi-trash" className="p-button-danger" rounded disabled={loading} onClick={() => confirm(false)} tooltip="Delete selected client" tooltipOptions={{position: "bottom"}} /> */}
      <Button type="button" icon="pi pi-file-export" className='mx-3' rounded disabled={loading} onClick={() => exportCSV(false)} tooltip="Download as csv" tooltipOptions={{position: "bottom"}} />

  </React.Fragment>
);

const footerContent = (
  <div>
      <Button label="No" icon="pi pi-times" onClick={() => setCreateVisible(false)} className="p-button-text" />
      <Button label="Yes" icon="pi pi-check" onClick={() => confirm(true)} autoFocus />
  </div>
);

const clear = () => {
  toastBC.current.clear();
};

const confirm = (create, newData) => {
  if(create === null){
    toastBC.current.show({
      severity: 'info',
      sticky: true,
      className: 'border-none',
      content: (
          <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
              <div className="text-center">
                  <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                  <div className="font-bold text-xl my-3">Are you sure?</div>
              </div>
              <div className="flex align-items-center">
                  <Button onClick={(e) => updateClient(newData)} type="button" label="Yes" className="p-button-success w-6rem" />
                  <Button onClick={(e) => clear(false)} type="button" label="No" className="p-button-warning w-6rem mx-4" />
              </div>
          </div>
      )
    });
  }
  else
  {
    if(create){
      if(newClientName=== undefined || newClientName === ''){
        toast.current.show({severity: 'error', summary: '', detail: 'Please enter a name for the new client.'});
        return;
      }
      console.log(newClientLms);
      if(newClientLms=== undefined || newClientLms === -1 || newClientLms === ''){
        toast.current.show({severity: 'error', summary: '', detail: 'Please select LMS for the new client.'});
        return;
      }
      toastBC.current.show({
        severity: 'info',
        sticky: true,
        className: 'border-none',
        content: (
            <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                    <div className="font-bold text-xl my-3">Are you sure?</div>
                </div>
                <div className="flex align-items-center">
                    <Button onClick={(e) => createClient()} type="button" label="Confirm" className="p-button-success w-6rem" />
                    <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                </div>
            </div>
        )
      });
    }
    else{
      toastBC.current.show({
        severity: 'info',
        sticky: true,
        className: 'border-none',
        content: (
            <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                    <div className="font-bold text-xl my-3">Are you sure?</div>
                </div>
                <div className="flex align-items-center">
                    <Button onClick={(e) => deleteClient()} type="button" label="Confirm" className="p-button-success w-6rem" />
                    <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                </div>
            </div>
        )
      });
    }
  }

};

const createClient = async () => {
  clear(true);
  var lmsKey = getKeybyValue(lmsConversion, newClientLms);
  const token = localStorage.getItem('token');
  var newClient = {
    id: 0,
    name: newClientName,
    locationId: typeId,
    status: false,
    lms: lmsKey
  }
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
    }
  };
  try {

    const response = await axios.post(global.config.url + '/clients', newClient, axiosConfig);
    console.log(response);
    if(response.status === 201){
      msgs.current.show({severity: 'success', summary: '', detail: `Client ${response.data.name} successfully created.`});
      getClients();
      setCreateVisible(false);
      setNewClientLms(0);
      setNewClientName('');
    }
    else{
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating the client, please try again.'});
    }

  } catch (error) {
    console.error('Error fetching customers:', error);
    if (error.response && error.response.status === 401) {
      // Logout and redirect to login screen
      await logout();
      navigate('/login');
    }
    else if(error.response && error.response.status === 400){
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating the client, please try again.'});
    }
    else if(error.response && error.response.status === 500){
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating the client, please try again.'});
    }
    else if(error.response && error.response.status === 409){
      toast.current.show({severity: 'error', summary: '', detail: error.response.data});
    }
  }

};

const deleteClient = async () => {
  clear(true);
  const token = localStorage.getItem('token');
  if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
    msgs.current.show({severity: 'error', summary: '', detail: 'Please select a client to delete.'});
    return;
  }
  try {
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
      }
    };
    const response = await axios.delete(global.config.url + `/clients/${rowSelectionModel.id}`, axiosConfig);
    console.log(response);
    if(response.status === 204){
      msgs.current.show({severity: 'success', summary: '', detail: `Client deleted successfully.`});
      getClients();
      setCreateVisible(false);
    }
    else{
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting the client, please try again.'});
    }

  } catch (error) {
    console.error('Error fetching customers:', error);
    if (error.response && error.response.status === 401) {
      // Logout and redirect to login screen
      await logout();
      navigate('/login');
    }
    else if(error.response && error.response.status === 400){
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting the client, please try again.'});
    }
    else if(error.response && error.response.status === 500){
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting the client, please try again.'});
    }
  }
  
};

const updateClient = async (newData) => {
  try{
    clear(true);
    const token = localStorage.getItem('token');
    var axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    const response = await axios.put(global.config.url + `/clients/${newData.id}`, newData, axiosConfig);
    console.log(response);
    if(response.status === 204){
      msgs.current.show({severity: 'success', summary: '', detail: `Client ${newData.name} successfully updated.`});
      getClients();
      setCreateVisible(false);
    }
    else{
      msgs.current.show({severity: 'error', summary: '', detail: 'Error updating the client.'});
    }
  } catch (error) {
    console.error('Error updating client:', error);
    if (error.response && error.response.status === 401) {
      // Logout and redirect to login screen
      await logout();
      navigate('/login');
    }
  }
};


const getKeybyValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

  const getSeverity = (client) => {
    switch (client.status) {
        case true:
            return 'success';

        case false:
            return 'danger';

        default:
            return null;
    }
};

const setClientChanges = (clients) => {
  //call db to update access points
  setClients(clients);
}

const onRowEditComplete = (e) => {

  let { newData, index } = e;
  let lmsName = newLmsName;
  if(lmsName === undefined || lmsName === ''){
    lmsName = lmsConversion[newData.lms];
  }
  console.log(lmsConversion)
  let lmsKey = getKeybyValue(lmsConversion, lmsName);
  newData.lms = lmsKey;
  let _clients = [...clients];
  _clients[index] = newData;
  setClientChanges(_clients);
  confirm(null, newData);
};

const textEditor = (options) => {
  return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

const setNewLms = (options, value) => {
  options.editorCallback(value);
  setNewLmsName(value);
}

const lmsEditor = (options) => {
  return (
      <Dropdown
          value={options.value}
          options={lms}
          onChange={(e) => setNewLms(options, e.value)}
          placeholder="Select a LMS"
      />
  );
};

  const statusBodyTemplate = (client) => {
      return <Tag value={client.status.toString()} severity={getSeverity(client)}></Tag>;
  };

  const optionsSelected = (e, rowData) =>{
    op.current.toggle(e);
    setRowSelectionModel(rowData);
  }

  const optionsTemplate = (rowData) => {
    return <Button link icon="pi pi-ellipsis-v" onClick={(e) => optionsSelected(e, rowData)} />;
  };

  const progressbar = (        
    <div className="card mt-3">
        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
    </div>);


  return (
    <Card className='justify-content-md-center mx-5'>
      <Container >
        <div className="panel">
          <Toolbar           
          start={
            <Container className="flex align-content-center flex-wrap">
              <Button link icon="pi pi-arrow-left" onClick={() => navigate(-1)} className='flex'/> 
              <h4 className='flex mt-2'>Clients for {type.toLocaleLowerCase()} '{typeName}'</h4> 
            </Container>} 
          end={endContent}/>
        </div>
        <Messages ref={msgs} />
        <Toast ref={toastBC} position="top-center" />
        {loading && progressbar}

        <div className='justify-content-md-center mt-3'>
            <DataTable value={clients} selectionMode={'single'} selection={rowSelectionModel} onSelectionChange={(e) => setRowSelectionModel(e.value)} 
              dataKey="id" tableStyle={{ minWidth: '50rem' }} filters={filters} ref={dt} emptyMessage={loading ? "Loading clients..." : "No clients found."}
              globalFilterFields={['name', 'status', 'lmsConversion', 'location']} editMode='row' onRowEditComplete={onRowEditComplete}>
                <Column field="options" header="" body={optionsTemplate}> </Column>
                <Column field="name" header="Name" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} 
                  editor={(options) => textEditor(options)} ></Column>
                <Column header="Status" body={statusBodyTemplate}></Column>
                <Column field="lmsConversion" header="LMS" editor={(options) => lmsEditor(options)}></Column>
                <Column field="location" header="Location" ></Column>
                <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>

          </DataTable>
          <OverlayPanel ref={op}>
            {startContent}
          </OverlayPanel>
        </div>
        <div className="card flex justify-content-center">
          <Dialog header="Create Client" className='justify-content-center' visible={createVisible} onHide={() => setCreateVisible(false)} 
            style={{ width: 'auto' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footerContent}>
            <Toast ref={toast} />
            <Container className="flex align-content-center flex-wrap">
              <span className="flex p-float-label my-4">

                <InputText
                    id="name"
                    name="name"
                    value={newClientName}
                    onChange={(e) => setNewClientName(e.target.value)}
                />
                <label htmlFor="input_name">Name</label>
              </span>
              <span className="flex p-float-label my-4 ml-3">
                <Dropdown
                  inputId="dd-lms"
                  value={newClientLms}
                  options={lms}
                  onChange={(e) => setNewClientLms(e.value)}
                  placeholder="Select an LMS"    

                />
                <label htmlFor="dd_lms">Select an LMS</label>
              </span>
            </Container>
          </Dialog>
        </div>
      </Container>
      <ScrollTop />
    </Card>
  );
};

export default Clients;
