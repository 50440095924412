import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// import axios from 'axios';

// import Navbar from './components/Navbar';
import Home from './components/Home';
import Customers from './components/Customers';
import Locations from './components/Locations';
import Clients from './components/Clients';
import AccessPoints from './components/AccessPoints';
import EventRecords from './components/EventRecords';
import Logs from './components/Logs';
import ClientLogs from './components/ClientLogs';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import APExtraInfo from './components/APExtraInfo';
import HubRelay from './components/HubRelay';


const App = () => {
  // const [navbarItems, setNavbarItems] = useState([]);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [list, setList] = useState([]);

  const replaceHastPath = () => {
    const history = createBrowserHistory();
    const hash = history.location.hash;
    if(hash){
      const path = hash.replace(/^#/, '');
      if(path){
        history.replace(path);
      }
    }
  }

  replaceHastPath();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchNavbarItems = async () => {
        try {
          // Replace this with the API call to fetch navbar items
          // const response = await axios.get('https://localhost:7263/api/Auth/profile', {
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //   },
          // });
          // setNavbarItems(response.data);
        } catch (error) {
          console.error('Error fetching navbar items:', error);
        }
      };

      fetchNavbarItems();
    }
  }, []);

  // const handleLoginSuccess = (data) => {
  //   setIsLoggedIn(true);
  //   setList(['Customers', 'Locations', 'Clients', 'Access Points']);
  // };

  return (
    <BrowserRouter> 
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/locations/:customerName/:customerId" element={<Locations />} />
          <Route path="/clients/:type/:typeName/:typeId" element={<Clients />} />
          <Route path="/accesspoints/:type/:typeName/:typeId" element={<AccessPoints />} />
          <Route path="/events/:type/:typename/:id" element={<EventRecords />} />
          <Route path="/logs/:type/:typename/:id" element={<Logs />} />
          <Route path="/clientlogs/:type/:typename/:id" element={<ClientLogs />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/extra-info/:id' element={<APExtraInfo />} />
          <Route path='/hubrelay/:apid' element={<HubRelay />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;


