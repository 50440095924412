import React, { useState, useEffect, useRef  } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useAuth } from '../contexts/AuthContext';
import Container from 'react-bootstrap/Container';
import { Toast } from 'primereact/toast';
import { ScrollTop } from 'primereact/scrolltop';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressBar } from 'primereact/progressbar';
import '../config.js'

const AccessPoints = () => {
  const { type, typeId, typeName } = useParams();
  const [accessPoints, setAccessPoints] = useState([]);
  const [accessPointsShow, setAccessPointsShow] = useState([]);
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState();
  const { logout } = useAuth();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    relay: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tile: { value: null, matchMode: FilterMatchMode.STARTS_WITH},
    gate: { value: null, matchMode: FilterMatchMode.STARTS_WITH},
    client : { value: null, matchMode: FilterMatchMode.STARTS_WITH},
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const msgs = useRef(null);
  const [createVisible, setCreateVisible] = useState(false);
  const [newAPRelay, setNewAPRelay] = useState('');
  const [newAPGate, setNewAPGate] = useState('');
  const [newAPLocation, setNewAPLocation] = useState(-1);
  const [stLocations, setLocations] = useState([]);
  const [newAPClient, setNewAPClient] = useState(-1);
  const [stClients, setClients] = useState([]);
  const [stCustomers, setCustomers] = useState([]);
  const toast = useRef(null);
  const toastBC = useRef(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [copyVisible, setCopyVisible] = useState(false);
  const [innovatiseLink, setInnovatiseLink] = useState('');
  const [newLink, setNewLink] = useState('');
  var [updatedAP, setUpdatedAP] = useState(0);
  const [newCLientName, setNewClientName] = useState('');
  const [islocation, setIsLocation] = useState(false);
  const [memberNumber, setMemberNumber] = useState('');
  const [testMemberVisible, setTestMemberVisible] = useState(false);



  const op = useRef(null);
  const link = useRef(null);
  const nlink = useRef(null);
  const dt = useRef(null);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
        relay: { value: '', matchMode: 'contains' },
        tile: { value: '', matchMode: 'contains' },
        gate: { value: '', matchMode: 'contains' },
        'client.name': { value: '', matchMode: 'contains' },
        'location.name': { value: '', matchMode: 'contains' },
    }
});

const onSort = (event) => {
  console.log(event);
  setlazyState(event);
};

  useEffect(() => {
    const fetchAccessPoints = async () => {
      await getAccessPoints();
    };

    fetchAccessPoints();
  }, [typeId, typeName, lazyState]);

  useEffect(() => {

    // updateAccessPoint();
  }, [updatedAP]);

  const getAccessPoints = async () => {
    setIsLocation(type === 'Location');
    try {
      setLoading(true);
      setAccessPoints([]);
      const token = localStorage.getItem('token');
      let header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      console.log("get access points " + type + " " + typeId);
      const response = await axios.get(global.config.url + `/accesspoints/By${type}/${typeId}`, header);
        setAccessPoints(response.data);
        setLoading(false);
        const ap = [...response.data];
        // setAccessPointsShow(ap.sort((a, b) => (a.tile < b.tile) ? 1 : -1));
        setAccessPointsShow(ap);
        const endpoint = type.toLowerCase() === 'client' ? `/clients/ByLocation/${ap[0].locationId}` : `/clients/By${type}/${typeId}`;
        const responseClients = await axios.get(global.config.url + endpoint, header);
        setClients(responseClients.data);
        if(type !== 'Customer'){
          const responseLoc = await axios.get(global.config.url + `/locations/ByCustomer/${ap[0].location.customerId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setLocations(responseLoc.data);
        }
    } catch (error) {
      if (error.response && error.response.status === 401) {
          // Logout and redirect to login screen
          await logout();
          navigate('/login');
        }
    }
  };

  const getLocations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(global.config.url + `/locations/ByCustomer/${typeId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      setLocations(response.data);
      console.log(stLocations);
    } catch (error) {
      console.error('Error fetching locations:', error);
      if (error.response && error.response.status === 401) {
        // Logout and redirect to login screen
        await logout();
        navigate('/login');
      }
    }
  };

  const getClients = async (value, ty) => {
    try {
      const token = localStorage.getItem('token');
      if(value === undefined){
        value = typeId;
      }
      const endpoint = ty.toLowerCase() === 'client' ? `/clients/${value}` : `/clients/By${ty}/${value}`;
      const response = await axios.get(global.config.url + endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      const responseClients = [];
      responseClients.push(response.data);
      setClients(responseClients);
    } catch (error) {
      console.error('Error fetching client:', error);
      if (error.response && error.response.status === 401) {
        // Logout and redirect to login screen
        await logout();
        navigate('/login');
      }
    }
  };

  const getEvents = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.gate === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select an access point to view logs.'});
    }
    else{
      navigate(`/events/accesspoint/${rowSelectionModel.gate} at ${typeName}/${rowSelectionModel.id}`);
    }
  };
  const getLogs = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.gate === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select an access point to view logs.'});
    }
    else{
      navigate(`/logs/accesspoint/${rowSelectionModel.gate} at ${typeName}/${rowSelectionModel.id}`);
    }
  };

  const testAP = async (member) => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.gate === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select an access point to test.'});
    }
    else{
      const token = localStorage.getItem('token');
      let url = global.config.url + `/accesspoints/testmember/${rowSelectionModel.id}/${memberNumber}`;
      if(!member || memberNumber === '' || memberNumber === undefined || memberNumber === null){
        url = global.config.url + `/accesspoints/test/${rowSelectionModel.id}`;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log(response);
      if(response.status === 200){
        if(response.data === true){
            msgs.current.show({severity: 'success', summary: '', detail: 'Access allowed by lms.'});
        }
        else{
            msgs.current.show({severity: 'warn', summary: '', detail: 'Access denied by lms.'});
        }
      }
      else{
        msgs.current.show({severity: 'error', summary: '', detail: 'Error testing access point.'});
      }
    }
  };


  const openScannerReader = async () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.gate === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select an access point to open the reader configuration.'});
    }
    else{
      const token = localStorage.getItem('token');
      const response = await axios.get(global.config.url + `/accesspoints/reader/${rowSelectionModel.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log(response);
      let url = response.data;
      if(response.status === 200){
        window.open(url, '_blank');
      }
      else{
        msgs.current.show({severity: 'error', summary: '', detail: 'Error opening the reader configuration.'});
      }
      // navigate(`/logs/accesspoint/${rowSelectionModel.gate} at ${typeName}/${rowSelectionModel.id}`);
    }
  };

  const getExtraInfo =() => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.gate === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select an access point to view extra info.'});
    }
    else{
      navigate(`/extra-info/${rowSelectionModel.id}`);
    }
  }

  const getHubRelays = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.gate === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select an access point to view hub relays.'});
    }
    else{
      navigate(`/hubrelay/${rowSelectionModel.id}`);
    }
  }

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const setAccessPointsChanges = (accessPoint) => {
    console.log(accessPoint["client.name"]);
    console.log(stClients)
    let client = stClients.find(x => x.name === accessPoint["client.name"]);
    accessPoint.clientId = client.id;
    accessPoint.client = client;
    console.log(accessPoint);
    setAccessPoints(accessPoint);
  }

  const onRowEditComplete = async (e) => {
    let { newData, index } = e;
    let clientName = newCLientName;
    if(clientName === undefined || clientName === null || clientName === ""){
      clientName = newData.client.name;
    }
    let client = stClients.find(x => x.name === clientName);
    newData.clientId = client.id;
    newData.client = client;
    let aps = [...accessPoints];
    aps[index] = newData;
    setAccessPoints(aps);
    confirm(null, newData);
  };

  const updateAccessPoint = async (newdata) => {
    try {
      // if(newdata === undefined){ return; }
      clear(false)
      const token = localStorage.getItem('token');
      var axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(global.config.url + `/accesspoints/${newdata.id}`, newdata, axiosConfig);
      if(response.status === 200){
        msgs.current.show({severity: 'success', summary: '', detail: 'Access point updated successfully.'});
      }
      else{
        msgs.current.show({severity: 'error', summary: '', detail: 'Error updating access point.'});
      }
    } catch (error) {
      console.error('Error updating access point:', error);
      if (error.response && error.response.status === 401) {
        // Logout and redirect to login screen
        await logout();
        navigate('/login');
      }
    }
  }

  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const setNewClient = (options, value) => {
    options.editorCallback(value);
    setNewClientName(value);
  }

  const setDropdownClients = (options) => {
    if(type === 'Customer') return;
    return <Dropdown
      value={stClients.find(x => x.name === options.value).name}
      options={stClients.map(x => x.name)}
      onChange={(e) => setNewClient(options, e.value)}
      placeholder="Select a Client"
    />;
  }

  const setDropdownLocations = async (options) => {

    if(type === 'Customer') return;
    // return <Dropdown
    //   value={stLocations.find(x => x.name === options.value).name}
    //   options={stLocations.map(x => x.name)}
    //   onChange={(e) => options.editorCallback(e.value)}
    //   placeholder="Select a Location"
    // />;


  }

  const sendQRCodes = async () => {
    let token = localStorage.getItem('token');
    let axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    try {
      const response = await axios.get(global.config.url + `/locations/qr/${typeId}`, axiosConfig);
      if(response.status === 200){
        if(response.data === true){
          msgs.current.show({severity: 'success', summary: '', detail: `QR codes sent successfully.`});
        }
        else{
          msgs.current.show({severity: 'error', summary: '', detail: `Error sending QR codes, check if the links have been submitted and try again.`});
        }
      }
      else{
        msgs.current.show({severity: 'error', summary: '', detail: `Error sending QR codes, please try again.`});
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      await logout();
      navigate('/login');
    }
  }

  const startContent = (
    <React.Fragment>
        <Button onClick={() => getLogs()} label="Get Access Logs" icon="pi pi-file" className="p-button mx-5" tooltip="Get access logs for this access point" tooltipOptions={{position:"bottom"}}/>
        {/* <Button onClick={() => getEvents()} label="Get Events" icon="pi pi-file" className="p-button-warning mx-5" tooltip="Get events for this access point" tooltipOptions={{position:"bottom"}}/> */}
        {/* <Button onClick={() => getExtraInfo()} label="Extra Info" icon="pi pi-info-circle" className="p-button-info" tooltip="Get extra information for this access point" tooltipOptions={{position:"bottom"}}/> */}
        <Button onClick={() => getHubRelays()} label='Hub and Relays' icon="pi pi-sitemap" className="p-button-success " tooltip="Get hub and relays for this access point" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => openScannerReader()} label='Add Scanner Reader' icon="pi pi-qrcode" className="p-button-secondary mx-5" tooltip="Open scanner reader for this access point" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={(e) => testAP(false)} label='Quick Test' icon="pi pi-send" className="p-button-help is-light" tooltip="Test this access point" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={(e) => setTestMemberVisible(true)} label='Test Member' icon="pi pi-send" className="p-button-info is-light mx-5" tooltip="Test this access point" tooltipOptions={{position:"bottom"}}/>
    </React.Fragment>
);


const copyContent = (
  <React.Fragment>

    <DataTable value={accessPointsShow} dataKey="id" showGridlines filters={filters} globalFilterFields={['tile', 'gate', 'location.name']} >
      <Column field="tile" header="Tile"  />
      <Column field="gate" header="Gate Name" />
      <Column field="location.name" header="Location"  />
    </DataTable>
  </React.Fragment>
);

const testMemberAP = (
  <React.Fragment>

    <InputText type="text" id="memberNumber" value={memberNumber} placeholder="Member number..." onChange={(e) => setMemberNumber(e.target.value)} required/>
    <Button onClick={() => testAP(true)} label='Test Member' icon="pi pi-send" className="p-button-help is-light mx-5" tooltip="Test this access point" tooltipOptions={{position:"bottom"}}/>
  </React.Fragment>
);

const filterContent = (
    <span className="p-input-icon-left mx-2 my-5">
      <i className="pi pi-search" />
      <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Filter by relay, tile, gate or client name" tooltip="Start typing a relay, tile, gate or client name" tooltipOptions={{position: "bottom"}}  />
    </span>
);

const endContent = (
  <React.Fragment>
      {filterContent}
      <Button icon="pi pi-refresh" className="p-button-text" onClick={() => getAccessPoints()} disabled={loading} tooltip="Refresh access points" tooltipOptions={{position: "bottom"}}/>
      <Button icon="pi pi-copy" className='p-button-secondary mr-3 ml-2' onClick={(e) => setCopyVisible(true)} disabled={loading} rounded tooltip="Show access points in copy format" tooltipOptions={{position: "bottom"}}/>
      <Button icon="pi pi-plus" className="p-button-success" rounded loading={createLoading} disabled={loading} onClick={() => createAP()} tooltip="Create new access point" tooltipOptions={{position: "bottom"}} />
      <Button icon="pi pi-trash" className="p-button-danger mx-3" rounded loading={deleteLoading} disabled={loading} onClick={() => confirm(false)} tooltip="Delete selected access point" tooltipOptions={{position: "bottom"}} />
      <Button type="button" icon="pi pi-file-export" className='' rounded disabled={loading} onClick={() => exportCSV(false)} tooltip="Download as csv" tooltipOptions={{position: "bottom"}} />
      <Button icon="pi pi-qrcode" className='p-button-help is-light mx-3' rounded disabled={!islocation || loading}  onClick={() => sendQRCodes()} tooltip="Send QR codes for all access points in this location" tooltipOptions={{position: "bottom"}} />

  </React.Fragment>
);

const footerContent = (
  <div>
      <Button label="No" icon="pi pi-times" onClick={() => cancelNewAP(false)} className="p-button-text" />
      <Button label="Yes" icon="pi pi-check" onClick={() => confirm(true)} autoFocus />
  </div>
);

const createAP = () => {
  if(type === 'Customer'){
    getLocations();
  }
  if(type === 'Location'){
    setNewAPLocation(typeId);
    getClients(typeId, 'Location');
  }
  console.log(stClients);
  setCreateVisible(true);
}

const cancelNewAP = () => {
  setNewAPGate('');
  setNewAPRelay('');
  setNewAPLocation(-1);
  setCreateVisible(false);
}

const clear = () => {
  toastBC.current.clear();
};

const confirm = (create, newdata) => {
  if(create === null){
    toastBC.current.show({
      severity: 'info',
      sticky: true,
      className: 'border-none',
      content: (
          <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
              <div className="text-center">
                  <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                  <div className="font-bold text-xl my-3">Are you sure?</div>
              </div>
              <div className="flex align-items-center">
                  <Button onClick={(e) => updateAccessPoint(newdata)} type="button" label="Confirm" className="p-button-success w-6rem" />
                  <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
              </div>
          </div>
      )
    });
  }
  else{
    if(create){
      if(newAPRelay=== undefined || newAPRelay === ''){
        toast.current.show({severity: 'error', summary: '', detail: 'Please enter a relay for the new access point.'});
        return;
      }
      if(newAPGate=== undefined || newAPGate === ''){
        toast.current.show({severity: 'error', summary: '', detail: 'Please select gate name for the new access point.'});
        return;
      }
      toastBC.current.show({
        severity: 'info',
        sticky: true,
        className: 'border-none',
        content: (
            <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                    <div className="font-bold text-xl my-3">Are you sure?</div>
                </div>
                <div className="flex align-items-center">
                    <Button onClick={(e) => createAccessPoint()} type="button" label="Confirm" className="p-button-success w-6rem" />
                    <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                </div>
            </div>
        )
      });
    }
    else{
      toastBC.current.show({
        severity: 'info',
        sticky: true,
        className: 'border-none',
        content: (
            <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                    <div className="font-bold text-xl my-3">Are you sure?</div>
                </div>
                <div className="flex align-items-center">
                    <Button onClick={(e) => deleteAccessPoint()} type="button" label="Confirm" className="p-button-success w-6rem" />
                    <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                </div>
            </div>
        )
      });
    }
  }

};

const createAccessPoint = async () => {
  setCreateLoading(true);
  clear(true);
  const token = localStorage.getItem('token');
  var newAP = {
    "id": 0,
    "relay": newAPRelay,
    "tile": "string",
    "locationId": 0,
    "location": {      
      "id": 0,
      "name": "stromg",
      "customerId": 0,
      "lms": 0,
      "extra": "string",
      "status": false
    },
    "gate": newAPGate,
    "clientId":  parseInt(typeId),
    "client": {
      "id": 0,
      "name": "string",
      "locationId": 0,
      "status": false,
      "lms": 0
    }
  }
  if(type === 'Customer'){
    newAP.locationId = newAPLocation;
    newAP.clientId = newAPClient;
    newAP.client.id = newAPClient;
    newAP.client.locationId = newAPLocation;
  }
  else if(type === 'Location'){
    newAP.locationId = parseInt(typeId);
    newAP.clientId = newAPClient;
    newAP.client.id = newAPClient;
    newAP.client.locationId = parseInt(typeId);
  }

  let axiosConfig = {
    headers: {
        // 'Content-Type': 'application/json;charset=UTF-8',
        // "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
    }
  };
  try {
    const response = await axios.post(global.config.url + '/accesspoints', newAP, axiosConfig);
    console.log(response);
    if(response.status === 201){
      msgs.current.show({severity: 'success', summary: '', detail: `Access point successfully created.`});
      getAccessPoints();
      setCreateVisible(false);
      setNewAPGate('');
      setNewAPRelay('');
    }
    else{
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating the access point, please try again.'});
    }

  } catch (error) {
    console.error('Error creating access point:', error);
    if (error.response && error.response.status === 401) {
      // Logout and redirect to login screen
      await logout();
      navigate('/login');
    }
    else if(error.response && error.response.status === 400){
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating the access point, please try again.'});
    }
    else if(error.response && error.response.status === 500){
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating the access point, please try again.'});
    }
    else if(error.response && error.response.status === 409){
      toast.current.show({severity: 'error', summary: '', detail: error.response.data});
    }
  }
  setCreateLoading(false);
};

const deleteAccessPoint = async () => {
  setDeleteLoading(true);
  clear(true);
  const token = localStorage.getItem('token');
  if(rowSelectionModel === undefined || rowSelectionModel === 0){
    msgs.current.show({severity: 'error', summary: '', detail: 'Please select an access point to delete.'});
    return;
  }
  try {
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
      }
    };
    const response = await axios.delete(global.config.url + `/accesspoints/${rowSelectionModel}`, axiosConfig);
    console.log(response);
    if(response.status === 204){
      msgs.current.show({severity: 'success', summary: '', detail: `Access point deleted successfully.`});
      getAccessPoints();
      setCreateVisible(false);
    }
    else{
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting the access point, please try again.'});
    }

  } catch (error) {
    console.error('Error fetching customers:', error);
    if (error.response && error.response.status === 401) {
      // Logout and redirect to login screen
      await logout();
      navigate('/login');
    }
    else if(error.response && error.response.status === 400){
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting the access point, please try again.'});
    }
    else if(error.response && error.response.status === 500){
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting the access point, please try again.'});
    }
  }
  setDeleteLoading(false);
};

  const setNewLocation = (value) => {
    setNewAPLocation(value);
    console.log(value);
    // getClients(value, 'Location');
  }

  const optionsSelected = (e, rowData) =>{
    op.current.toggle(e);
    setRowSelectionModel(rowData);
  }

  const optionsTemplate = (rowData) => {
    return <Button link icon="pi pi-ellipsis-v" onClick={(e) => optionsSelected(e, rowData)} />;
  };

  const copyToClipboard = (e) => {
    console.log(e);
    var textField = document.createElement('textarea')
    textField.innerText = e;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  const linksPanel = (
      <React.Fragment>
        <div className="p-fluid p-formgrid p-grid">
        <Container className="flex align-content-center flex-wrap">
            <p id='qrlink' className='flex mt-3'> 
              <b>QR Link: </b> {innovatiseLink}?t=qr

            </p>
            <Button className="p-button-success flex" link icon="pi pi-copy" onClick={() => copyToClipboard(innovatiseLink+"?t=qr")} />
          </Container>
          <Container className="flex align-content-center flex-wrap">
            <p id='nfclink' className='flex mt-3'>
              <b>NFC Link: </b> {innovatiseLink}?t=nfc 
            </p>
            <Button className="p-button-success" link icon="pi pi-copy" onClick={() => copyToClipboard(innovatiseLink+"?t=nfc")} />
          </Container>
        </div>
        
      </React.Fragment>
  );

  const createLink = async () => {
    let token = localStorage.getItem('token');
    let axiosConfig = {
      headers: {
          Authorization: `Bearer ${token}`,
      }
    };
    const response = await axios.post(global.config.url + '/accesspoints/link', {id:0, accessPointId: rowSelectionModel.id, link: newLink}, axiosConfig);
    console.log(response);
    if(response.status === 201){
      setNewLink('');
      msgs.current.show({severity: 'success', summary: '', detail: `Link created successfully.`});
    }
     else 
    {
      msgs.current.show({severity: 'error', summary: '', detail: 'Error creating the link, please try again.'});
    }
  }

  const createLinkPanel = (
    <React.Fragment>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-12 p-md-12">
          <InputText id="newLink" value={newLink} onChange={(e) => setNewLink(e.target.value)} placeholder="Enter link" />
          
          <Button className="p-button-success mt-5" label="Create" onClick={createLink} />
        </div> 
      </div>
    </React.Fragment>
  );


  const getLinks = async (e, rowData) => {
    console.log(rowData);
    //get innovatise links from api
    let token = localStorage.getItem('token');
    let axiosConfig = {
      headers: {
          Authorization: `Bearer ${token}`,
      }
    };
    const response = await axios.get(global.config.url + `/accesspoints/link/${rowData.id}`, axiosConfig);
    console.log(response);
    if(response.status === 200){
      if(response.data.length === 0){
        nlink.current.toggle(e);
        return;
      }
      setInnovatiseLink(response.data[0].link);
      link.current.toggle(e);
    }
    else{
      toast.current.show({severity: 'error', summary: '', detail: 'Error fetching links, please try again.'});
    }
  }


  const linksTemplate = (rowData) => {
    return <Button link icon="pi pi-link" onClick={(e) => getLinks(e, rowData)} />;
  };

  const progressbar = (        
    <div className="card mt-3">
        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
    </div>);

  return (
    <Card className ="justify-content-md-center mx-5">
      <Container >
        <div className="card">
          
          <Toolbar 
            start={
              <Container className="flex align-content-center flex-wrap">
                <Button link icon="pi pi-arrow-left" onClick={() => navigate(-1)} className='flex'/> 
                <h4 className='flex mt-2'>Access points for {type.toLocaleLowerCase()} '{typeName}'</h4> 
              </Container>}
            end={endContent}/>
        </div>
        <Messages ref={msgs} />
        <Toast ref={toastBC} position="top-center" />
        {loading && progressbar}

        <div className='justify-content-md-center mt-3'>

          <DataTable value={accessPoints} selectionMode={'single'} selection={rowSelectionModel} onSelectionChange={(e) => setRowSelectionModel(e.value)} 
          dataKey="id" tableStyle={{ minWidth: '50rem' }}
          filters={filters} ref={dt} globalFilterFields={['relay', 'tile', 'gate', 'client.name', 'location.name']} 
          emptyMessage={loading ? "Loading access points..." :"No access points found."} editMode='row' onRowEditComplete={onRowEditComplete}>
              <Column field="options" header="" body={optionsTemplate}> </Column>
              <Column field="relay" header="Relay" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} 
                editor={(options) => textEditor(options)}></Column>
              <Column field="tile" header="Tile"></Column>
              <Column field="gate" header="Gate" editor={(options) => textEditor(options)}></Column>
              <Column field="client.name" header="Client Name" editor={(options) => setDropdownClients(options)} ></Column>
              {/* <Column field="location.name" header="Location Name" editor={(options) => setDropdownLocations(options)} ></Column> */}
              <Column field="location.name" header="Location Name" ></Column>
              <Column field="options" header="" body={linksTemplate}></Column>
              <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>

          </DataTable>
          <OverlayPanel ref={op}>
            {startContent}
          </OverlayPanel>
          <OverlayPanel ref={link}>
            {linksPanel}
          </OverlayPanel>
          <OverlayPanel ref={nlink}>
            {createLinkPanel}
          </OverlayPanel>
        </div>
        <div className="card flex justify-content-center">
          <Dialog header="Create Access Point" className='justify-content-center' visible={createVisible} onHide={() => cancelNewAP()} 
            style={{ width: 'auto' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footerContent}>
            <Toast ref={toast} />
            <Container className="flex align-content-center flex-wrap">
              <span className="flex p-float-label my-4">

                <InputText
                    id="relay"
                    name="relay"
                    value={newAPRelay}
                    onChange={(e) => setNewAPRelay(e.target.value)}
                />
                <label htmlFor="input_relay">Relay</label>
              </span>
              <span className="flex p-float-label my-4 ml-3 mr-3">
                <InputText
                    id="gate"
                    name="gate"
                    value={newAPGate}
                    onChange={(e) => setNewAPGate(e.target.value)}
                />
                <label htmlFor="input_gate">Gate</label>
              </span>
              {type === 'Customer' && createVisible && stLocations.length > 0 &&
                  <span className="flex p-float-label my-4 mr-3">
                    <Dropdown 
                      inputId = "dd-location"
                      value={newAPLocation}
                      options={stLocations.map((location) => ({id: location.id, name: location.name}))}
                      onChange={(e) => setNewLocation(e.value)} 
                      optionValue="id"
                      optionLabel="name"
                      placeholder='Select a Location'
                      className="w-full md:w-14rem"
                    />  
                    <label htmlFor="dd-location">Select a Location</label>
                  </span>}
                {(type === 'Customer' || type === 'Location') && createVisible && stClients.length > 0 && newAPLocation !== -1 &&
                  <span className="flex p-float-label my-4">
                    <Dropdown 
                      inputId = "dd-client"
                      value={newAPClient}
                      // options={stClients.map((client) => ({id: client.id, name: client.name}))}
                      options={new Array(stClients.find((client) => client.locationId === newAPLocation))}
                      onChange={(e) => setNewAPClient(e.value)} 
                      optionValue="id"
                      optionLabel="name"
                      placeholder='Select a client'
                      className="w-full md:w-14rem"
                    />  
                    <label htmlFor="dd-client">Select a Client</label>
                  </span>}
            </Container>
          </Dialog>
        </div>
        <div className="card flex justify-content-center">
          <Dialog header={filterContent} className='justify-content-center' visible={copyVisible} onHide={() => setCopyVisible(false)}>
          {copyContent}
          </Dialog>
        </div>
        <div className="card flex justify-content-center">
          <Dialog className='justify-content-center' visible={testMemberVisible} onHide={() => setTestMemberVisible(false)}>
          {testMemberAP}
          </Dialog>
        </div>

      </Container>
      <ScrollTop />
    </Card>
    
  );
};

export default AccessPoints;
